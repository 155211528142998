import Web3 from 'web3'
import {handleLetterChange, render} from './index'

global.web3 = new Web3(typeof global.web3 !== 'undefined'
    // using the meatmask's web3 is gonna be deprecated
    // so make our own shiny new one
    ? global.web3.currentProvider
    
    // user doesn't have a web3 enabled browser
    // so dump them into mainnet
    : new Web3.providers.HttpProvider("https://mainnet.infura.io/CSnm7OOmCo4sNe6u3dIp")

    // make the dafault view ropsten
    // : new Web3.providers.HttpProvider("https://ropsten.infura.io/CSnm7OOmCo4sNe6u3dIp")
)

let EtherWorld

export function GetPastEvents(name, descriptor){
    if(EtherWorld) return EtherWorld.getPastEvents(name, descriptor)
    return Promise.reject('tried to get past events before contract load')
}

export function Write(xs, ys, chars, value){
    return new Promise(async (resolve, reject) => {
        if(!EtherWorld) return reject()

        EtherWorld.methods.Write(xs, ys, chars)
        .send({ 
            value,
            from: (await global.web3.eth.getAccounts())[0],
            gasPrice: global.web3.utils.toWei(
                (await (await fetch('https://ethgasstation.info/json/ethgasAPI.json')).json()).safeLow.toString(),
                'gwei')

        })
        .on('transactionHash', function(hash){
            console.log('tx hash', hash)
            resolve(hash)
        })
        .on('receipt', function(receipt){
            console.log('reciept', reciept)
        })
        .on('confirmation', function(confirmationNumber, receipt){
            console.log('confirmed', confirmationNumber, reciept)
        })
        .on('error', function(err){
            console.error(err)
            reject(err)
        }); 
    })
}

;(async () => {
    const netId = await global.web3.eth.net.getId()

    console.log(netId)

    let ADDR, ABI, wsProviderURL

    if(netId == 1){ //mainnet
        ;({ADDR, ABI} = require('./live_contracts/mainnet/v1.json'))
        wsProviderURL = "wss://mainnet.infura.io/ws"
    } else if (netId == 3){ //ropsten
        document.title = '[Ropsten] ' + document.title
        ;({ADDR, ABI} = require('./live_contracts/ropsten/v2.json'))
        wsProviderURL = "wss://ropsten.infura.io/ws"
    } else {

    }

    const wsProvider = new Web3.providers.WebsocketProvider(wsProviderURL)
    const {Contract} = new Web3(wsProvider).eth
    const wsContract = new Contract(ABI, ADDR, {})

    global.wsContract = wsContract

    wsContract.events.LetterChange({}, (err, event) => {
        if(err) return console.error(err);
        console.log(event.returnValues)
        handleLetterChange(event.returnValues)
    })

    EtherWorld = new web3.eth.Contract(ABI, ADDR, {})
    global.EtherWorld = EtherWorld

    requestAnimationFrame(render)
})()

if (module.hot) module.hot.accept(e => console.log('this prevents a circular deps bug with hot reloading'))