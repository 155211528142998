import 'babel-polyfill'

import * as gradient from './gradient'
import { PriceChange, LetterChange, Write, GetPastEvents} from './contract'
import ramjet from 'ramjet'

//global web3 set in contract
const {utils} = global.web3

const ctx = canvas.getContext('2d')

ctx.font = '30px Menlo, monospace'
const dims = ctx.measureText('a')

const GRIDWIDTH = dims.width
const GRIDHEIGHT = 30
const MAX_ZOOM = 1.25
const MIN_ZOOM = .25

const letters = {}
const prices = {}

let local_letters = {}
let pending_letters = {}
let loaded_tiles = {}


let zoom = 1
let x = 0//GRIDWIDTH * 100
let y = 0//GRIDHEIGHT * 100

const coords = location.pathname.slice(1).split(',')
console.log(coords)
if(coords.length == 2){
    x = +coords[0]*GRIDWIDTH
    y = +coords[1]*GRIDHEIGHT
}

let heatmap = false

// let x = 0;
// let y = 0;


let focus = [Math.floor(x / GRIDWIDTH), Math.floor(y/GRIDHEIGHT)]
let linestart = [].slice.call(focus)



export function handleLetterChange({letter, price, tile_x, tile_y, x, y}){
    const pos = [x,y]
    letters[pos] = String.fromCharCode(parseInt(letter.slice(2), 16))
    prices[pos] = price
    delete pending_letters[pos];

    requestAnimationFrame(render)
}



function screen_x(vx, cx=x, z=zoom){
    return (vx - cx) / z + canvas.width / 2
}

function screen_y(vy, cy=y, z=zoom){
    return (vy - cy) / z + canvas.height / 2
}

function draw_vline(lx){
    let realx = screen_x(lx)
    ctx.beginPath()
    ctx.moveTo(realx, 0)
    ctx.lineTo(realx, canvas.height)
    ctx.stroke()
}

function draw_hline(ly){
    let realy = screen_y(ly)
    ctx.beginPath()
    ctx.moveTo(0, realy)
    ctx.lineTo(canvas.width, realy)
    ctx.stroke()
}

// function draw_letter(x,y){
//  ctx.textAlign = 'center'
//  ctx.textBaseline = 'middle'
//  ctx.font = GRIDHEIGHT/zoom + 'px Menlo, monospace'

//  // let tile = last_tiles[[Math.floor(x / 256), Math.floor(y / 256)]]
//  // if(tile && tile.loaded){
//  //  // draw gray for loading
//  //  ctx.save()
//  //  // ctx.fillStyle = 'rgb(200,200,200)'
//  //  ctx.fillStyle = ((Math.floor(y / 256) + Math.floor(x / 256)) % 2) ? 'rgba(255,0,0,0.1)' : 'rgba(0,255,0,0.1)'

//  //  ctx.fillRect(
//  //      screen_x(x * GRIDWIDTH),
//  //      screen_y(y * GRIDHEIGHT),
//  //      GRIDWIDTH/zoom,
//  //      GRIDHEIGHT/zoom)
//  //  ctx.restore()
//  // }

//  const key = [x,y]

//  const letter = letters[key]

//  if(key in local_letters){
//      const letter = local_letters[key]
//      if(letter) ctx.fillText(letter, screen_x((x + .5) * GRIDWIDTH), screen_y((y + .5) * GRIDHEIGHT))
//  }else if(key in pending_letters){
//      const letter = pending_letters[key]
//      if(letter) {
//          ctx.save()
//          ctx.fillStyle = 'gray'
//          ctx.fillText(letter, screen_x((x + .5) * GRIDWIDTH), screen_y((y + .5) * GRIDHEIGHT))
//          ctx.restore()           
//      }
//  }else if(letter){
//      ctx.fillText(letter, screen_x((x + .5) * GRIDWIDTH), screen_y((y + .5) * GRIDHEIGHT))
//  }
// }


function draw_local_letter_box(x,y){
    const letter = local_letters[[x,y]]
    if(letter) {
        ctx.save()
        ctx.fillStyle = 'rgba(50,255,50,.4)'
        ctx.fillRect(
            screen_x(x * GRIDWIDTH),
            screen_y(y * GRIDHEIGHT),
            GRIDWIDTH/zoom,
            GRIDHEIGHT/zoom)
        ctx.restore()
    }
}

function draw_pending_letter_box(x,y){
    const letter = pending_letters[[x,y]]
    if(letter) {
        ctx.save()
        ctx.fillStyle = 'rgba(255,255,0,.7)'
        ctx.fillRect(
            screen_x(x * GRIDWIDTH),
            screen_y(y * GRIDHEIGHT),
            GRIDWIDTH/zoom,
            GRIDHEIGHT/zoom)
        ctx.restore()
    }
}

function get_visible_coords(block_width=GRIDWIDTH, block_height=GRIDHEIGHT, cx = x, cy=y, z=zoom){
    const coord_at_screen_left = cx - canvas.width / 2 * z
    const coord_at_screen_right = cx + canvas.width / 2 * z

    const start_vline_num = Math.floor(coord_at_screen_left / block_width)
    const end_vline_num = Math.ceil(coord_at_screen_right / block_width)

    const coord_at_screen_top = cy - canvas.height / 2 * z
    const coord_at_screen_bottom = cy + canvas.height / 2 * z

    const start_hline_num = Math.floor(coord_at_screen_top / block_height)
    const end_hline_num = Math.ceil(coord_at_screen_bottom / block_height)

    return [start_vline_num, start_hline_num, end_vline_num, end_hline_num]
}

function draw_grid(){
    const [start_vline_num, start_hline_num, end_vline_num, end_hline_num] = get_visible_coords()
    for(let line = start_vline_num; line <= end_vline_num; line++) {
        draw_vline(line * GRIDWIDTH)
    }

    for(let line = start_hline_num; line <= end_hline_num; line++) {
        draw_hline(line * GRIDHEIGHT)
    }
}

function draw_galaxy(scale, parallax, density, border=.5, dappled=r=>'rgba(255,255,255,.2)'){
    const box_width = GRIDWIDTH * scale,
        box_height = GRIDWIDTH * scale

    const z = Math.pow(zoom, 1/parallax)

    // const block_width = 
    const cx = x/parallax + scale,
          cy = y/parallax + scale


    const [sx, sy, ex, ey] = get_visible_coords(box_width, box_height, cx, cy, z)


    for(let x = sx-1; x <= ex; x++)
    for(let y = sy-1; y <= ey; y++) {

        let rand = Math.abs(x)+1
        for(let i = 0; i < (Math.abs(y)+1) % 23; i++) rand = rand * 16807 % 2147483647
        
        if(rand % 23 < density){
            ctx.fillStyle=dappled(rand)
            ctx.fillRect(
                screen_x((x - border/2) * box_width, cx, z),
                screen_y((y - border/2) * box_height, cy, z),
                box_width/z*border,
                box_height/z*border
            )
        }
    }
}

function draw_heatmap(){

    if(!heatmap) return;

    const [start_vline_num, start_hline_num, end_vline_num, end_hline_num] = get_visible_coords()

    const default_price = utils.toWei('.000083', 'ether')

    let min_price = Infinity, max_price = utils.toWei('.0001', 'ether');
    for(let x = start_vline_num; x <= end_vline_num; x++)
    for(let y = start_hline_num; y <= end_hline_num; y++) {
        const price = +(prices[[x,y]] || default_price)
        if(price > max_price) max_price = price
        if(price < min_price) min_price = price
    }

    for(let x = start_vline_num; x <= end_vline_num; x++)
    for(let y = start_hline_num; y <= end_hline_num; y++) {
        // console.log(1/Math.sqrt(x*x + y*y))

        if(!prices[[x,y]] || prices[[x,y]] === min_price) continue;

        const price = +(prices[[x,y]] || default_price)

        ctx.save()
        ctx.fillStyle = gradient.price((price - min_price)/(max_price - min_price))
        ctx.fillRect(
            screen_x(x * GRIDWIDTH),
            screen_y(y * GRIDHEIGHT),
            GRIDWIDTH/zoom,
            GRIDHEIGHT/zoom)
        ctx.restore()
    }
}

function draw_letters(){
    const [start_vline_num, start_hline_num, end_vline_num, end_hline_num] = get_visible_coords()

    // ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.font = GRIDHEIGHT/zoom + 'px Menlo, monospace'
    ctx.fillStyle = '#eee'

    // const target_size = GRIDHEIGHT/zoom/2
    // word_overlay.style.font = Math.round(target_size) + 'px Menlo, monospace'

    // word_overlay.style.left = screen_x((start_vline_num) * GRIDWIDTH) / 2
    // word_overlay.style.top = screen_y((start_hline_num) * GRIDHEIGHT) / 2
    // word_overlay.style.lineHeight = Math.round(target_size) + 'px'
    // word_overlay.style.transform = `scale(${target_size / Math.round(target_size)})`

    let total = ''

    for(let y = start_hline_num; y <= end_hline_num; y++) {
        let s = ''      

        let startx = null
        for(let x = start_vline_num; x <= end_vline_num; x++) {

            const loc = [x,y].toString()

            const letter = local_letters[loc]
                || pending_letters[loc]
                || letters[loc]


            if(!s.length && letter) {
                startx = x
                s += letter
            } else if(letter) {
                s += letter
            } else if(s.length && !letter){
                s += " "
            }


            // draw_letter(x, y)
            // x === (start_vline_num) &&
            // letters[[x,y]] &&
        }

        // ctx.fillStyle = heatmap ? '#fff' :'#000'
        if(startx != null) ctx.fillText(s, screen_x((startx) * GRIDWIDTH), screen_y((y + .5) * GRIDHEIGHT))
        // total += s +'\n'
        // console.log(s)
        // ctx.fillText(s, screen_x((start_vline_num + .5) * GRIDWIDTH), screen_y((y + .5) * GRIDHEIGHT))
    }

    // word_overlay.innerText = total

}

// function draw_local_letters(){
//  const [start_vline_num, start_hline_num, end_vline_num, end_hline_num] = get_visible_coords()

//  for(let xline = start_vline_num; xline <= end_vline_num; xline++) {
        
//      for(let yline = start_hline_num; yline <= end_hline_num; yline++) {
//          draw_local_letter(xline, yline)
//      }
//  }
// }

function draw_local_letter_boxes(){

    const [start_vline_num, start_hline_num, end_vline_num, end_hline_num] = get_visible_coords()

    Object.keys(local_letters)
        .map(key => key.split(','))
        .filter(([x,y]) => x >= start_vline_num
            && x <= end_vline_num
            && y >= start_hline_num
            && y <= end_hline_num)
        .forEach(([x,y]) => draw_local_letter_box(x,y))

}

function draw_pending_letter_boxes(){
    const [start_vline_num, start_hline_num, end_vline_num, end_hline_num] = get_visible_coords()

    Object.keys(pending_letters)
        .map(key => key.split(','))
        .filter(([x,y]) => x >= start_vline_num
            && x <= end_vline_num
            && y >= start_hline_num
            && y <= end_hline_num)
        .forEach(([x,y]) => draw_pending_letter_box(x,y))

}


function draw_cursor(){
    ctx.save()
    ctx.fillStyle = 'rgba(71, 115, 147, .4)'
    ctx.fillRect(
        screen_x(focus[0] * GRIDWIDTH),
        screen_y(focus[1] * GRIDHEIGHT),
        GRIDWIDTH/zoom,
        GRIDHEIGHT/zoom)
    ctx.strokeStyle = '#fff'
    ctx.strokeRect(
        screen_x(focus[0] * GRIDWIDTH),
        screen_y(focus[1] * GRIDHEIGHT),
        GRIDWIDTH/zoom,
        GRIDHEIGHT/zoom)
    ctx.restore()
}

var last_tiles = {};

function enter(tile){

    console.log('entering', tile)

    if(!last_tiles[tile]){
        // create and pull initial
        last_tiles[tile] = {
            block: 0
        }
    }

    GetPastEvents("LetterChange", { 
        fromBlock: last_tiles[tile].block, 
        toBlock: 'latest',
        filter: {
            tile_x: tile.toString().split(',')[0],
            tile_y: tile.toString().split(',')[1]
        }
    }).then(events => {
        last_tiles[tile].loaded = true;

        events.forEach(event => {
            const {letter, price, tile_x, tile_y, x, y} = event.returnValues
            
            const pos = [x,y]
            letters[pos] = String.fromCharCode(parseInt(letter.slice(2), 16))
            prices[pos] = price
            // let pos = [event.returnValues[0], event.returnValues[1]]

            // letters[pos] = String.fromCharCode(parseInt(event.returnValues[2].slice(2), 16))
        })
        requestAnimationFrame(render)
    })
    .catch(e => console.warn(e))
}

function leave(tile){
    // no longer in focus
    last_tiles[tile].active = false;
    // unsubscribe from listener
    // last_tiles[tile].watcher.unsubscribe()
    console.log("leaving", tile)
}

function draw_new_letters(){
    const [start_vline_num, start_hline_num, end_vline_num, end_hline_num] = get_visible_coords()

    for(let tile in last_tiles){
        last_tiles[tile].dirty = true;
    }

    for(let i = Math.floor(start_vline_num / 256); i < Math.ceil(end_vline_num / 256); i++){
        for(let j = Math.floor(start_hline_num / 256); j < Math.ceil(end_hline_num / 256); j++){
            let tile = [i, j];
            if(!last_tiles[tile] || !last_tiles[tile].active) enter(tile);
            last_tiles[tile].dirty = false;
            last_tiles[tile].active = true;
        }
    }

    for(let tile in last_tiles){
        if(last_tiles[tile].dirty && last_tiles[tile].active){
            leave(tile)
        }
    }

}

function update_cursor_info(){
    // Ξ
    const info = document.getElementById('info')
    info.innerText = `${
        prices[focus]
            ? utils.fromWei(utils.toBN(prices[focus]).mul(utils.toBN(6)).div(utils.toBN(5)), 'ether')
            : '0.0001'}ETH to change [${focus}]`
    info.href='/'+focus
}

export function render(){

    const {width, height} = canvas.parentElement.getBoundingClientRect()

    canvas.width = width * 2
    canvas.height = height * 2
    
    ctx.strokeStyle = '#ddd'
    update_save_button()
    update_cursor_info()

    draw_galaxy(60, 10, 24, 1, r => `rgba(255,100,255,${r%23/23/20})`)
    draw_galaxy(20, 8, 10, 1, r => `rgba(255,255,100,${r%23/23/20})`)
    draw_galaxy(5, 3, 1, .1)
    draw_galaxy(10, 2, 1, .1)
    draw_heatmap()
    draw_pending_letter_boxes()
    draw_local_letter_boxes()
    draw_cursor()
    draw_letters()
    // draw_local_letters()
    // draw_grid()
    draw_new_letters()
}

// canvas.addEventListener('mousemove', e => {
//  const mousex = (e.clientX * 2 - canvas.width / 2) * zoom + x
//  render()

//  ctx.strokeStyle = 'red'
//  draw_vline(mousex)

//  console.log(mousex)
// })

document.addEventListener('keydown', e => {
    const oldfocus = [...focus]

    if(e.keyCode >= 37 && e.keyCode <= 40) { // arrow key
        if(e.keyCode === 37) focus[0]--// left
        if(e.keyCode === 38) focus[1]--// up
        if(e.keyCode === 39) focus[0]++// right
        if(e.keyCode === 40) focus[1]++// down
        linestart = [].slice.call(focus)
    }
    
    if(e.keyCode === 8) { // delete
        if(linestart[0] === focus[0]) linestart[0]--
        focus[0]--

        if(focus in local_letters) delete local_letters[focus]
        else if(letters[focus]) local_letters[focus] = ' '
    }

    if(e.keyCode === 13) {//enter
        e.preventDefault()
        if(e.shiftKey) submit()
        else {
            focus = [].slice.call(linestart)
            linestart[1]++
            focus[1]++
        }
    }else if(e.keyCode === 83 && (e.metaKey || e.ctrlKey)){ // Cmd-S
        e.preventDefault()
        submit()
    }else if(e.keyCode === 90 && (e.metaKey || e.ctrlKey)){ // Cmd-Z
        e.preventDefault()
        submit()
    }

    if(oldfocus[0] != focus[0] || oldfocus[1] != focus[1]){
        const startx = (focus[0])*GRIDWIDTH
        const endx = (focus[0] + 1)*GRIDWIDTH
        const starty = (focus[1])*GRIDHEIGHT
        const endy = (focus[1] + 1)*GRIDHEIGHT

        if(screen_x(startx)<0) x += screen_x(startx)*zoom
        else if(screen_x(endx)>canvas.width) x += (screen_x(endx)- canvas.width)*zoom

        if(screen_y(starty)<0) y += screen_y(starty)*zoom
        else if(screen_y(endy)>canvas.height) y += (screen_y(endy)- canvas.height)*zoom
    }

    render()
})

function get_mouse(e){
    const {top, left} = canvas.getBoundingClientRect()

    const event_or_touch = e.touches
        ? e.touches[0] || e.changedTouches[0]
        : e

    const mousex = ((event_or_touch.clientX - left) * 2 - canvas.width / 2) * zoom + x
    const mousey = ((event_or_touch.clientY - top) * 2 - canvas.height / 2) * zoom + y

    return [mousex, mousey]
}


let drag_start_time
let last_drag_pos
canvas.addEventListener('mousedown', e => {
    last_drag_pos = get_mouse(e)
    drag_start_time = Date.now()

    console.log('mousedown')

    const decide = () => {
        window.removeEventListener('mousemove', start_drag)
        window.removeEventListener('mouseup', click)        
    }

    const start_drag = e => {
        decide()
    
        window.addEventListener('mousemove', drag, {passive: false})
        window.addEventListener('mouseup', finish_drag)
    }

    const finish_drag = e => {
        window.removeEventListener('mousemove', drag, {passive: false})
        window.removeEventListener('mouseup', finish_drag)
        if(Date.now() - drag_start_time < 400) do_click(e)
    }

    const click = e => {
        decide()
        do_click(e)
    }

    window.addEventListener('mousemove', start_drag)
    window.addEventListener('mouseup', click)

})


canvas.addEventListener('touchstart', e => {
    last_drag_pos = get_mouse(e)
    drag_start_time = Date.now()

    e.preventDefault()

    console.log('touchstart', e.touches.length)

    const decide = () => {
        window.removeEventListener('touchmove', start_drag)
        window.removeEventListener('touchend', click)       
    }

    const start_drag = e => {
        decide()
    
        window.addEventListener('touchmove', drag, {passive: false})
        window.addEventListener('touchend', finish_drag)
    }

    const finish_drag = e => {
        window.removeEventListener('touchmove', drag, {passive: false})
        window.removeEventListener('touchend', finish_drag)
        if(Date.now() - drag_start_time < 400) do_click(e)
    }

    const click = e => {
        decide()
        do_click(e)
    }

    window.addEventListener('touchmove', start_drag)
    window.addEventListener('touchend', click)

})


function do_click(e){
    const [mousex, mousey] = get_mouse(e)

    focus = [Math.floor(mousex / GRIDWIDTH), Math.floor(mousey / GRIDHEIGHT)]
    linestart = [].slice.call(focus)

    render()
}


function drag(e){

    console.log('dragging')

    e.preventDefault()
    const [mousex, mousey] = get_mouse(e)
    const [lastx, lasty] = last_drag_pos

    x -= (mousex - lastx)
    y -= (mousey - lasty)


    render()

    last_drag_pos = get_mouse(e)
}


function update_save_button(){
    const save = document.getElementById('save')
    save.classList.toggle('visible', 
        Object.keys(local_letters).filter(k => local_letters[k]).length > 0)
    save.innerText = `Save Edits (${utils.fromWei(get_total_price(), 'ether')}ETH)`
}

function type_char(char, rerender=true){
    local_letters[focus] = char

    if(char === ' ' && !letters[focus]){
        delete local_letters[focus]
    }

    focus[0]++

    if(rerender) render()
}

window.addEventListener('keypress', e => {

    const char = String.fromCharCode(e.keyCode)

    type_char(char)

})



document.addEventListener('paste', e => {
    e.preventDefault();
    const data = e.clipboardData.getData('Text')
    if(data) {
        const start = [...focus]
        data.split('\n').forEach(line => {
            line.split('').forEach(char => type_char(char, false))
            focus[0] = start[0]
            focus[1]++
        })
        render()
    }

})


document.addEventListener('copy', e => {
    e.preventDefault();
    const letter = local_letters[[focus]] || pending_letters[[focus]] || letters[[focus]] || ' '
    e.clipboardData.setData('text/plain', letter);
})

let scroll_zooms = false
canvas.addEventListener('wheel', e => {

    if(Math.abs(e.deltaX) > 0) scroll_zooms = false

    e.preventDefault()
    const [mousex, mousey] = get_mouse(e)


    if(e.ctrlKey || scroll_zooms) {
        const factor = Math.min(Math.max(Math.pow(1.01, e.deltaY), MIN_ZOOM/zoom), MAX_ZOOM/zoom)
        zoom *= factor

        x = mousex - factor * (mousex - x)
        y = mousey - factor * (mousey - y)
    } else {
        x += e.deltaX * zoom
        y += e.deltaY * zoom
    }
    render()

    return false;
})

function get_total_price(locations=Object.keys(local_letters)){
    return locations
        .map(l => prices[l]
            ? utils.toBN(prices[l])
                .mul(utils.toBN(6))
                .div(utils.toBN(5))
            : utils.toBN(utils.toWei('0.0001', 'ether')))
        .reduce((a,b)=>a.add(b),utils.toBN(0))
}


// https://ourcodeworld.com/articles/read/278/how-to-split-an-array-into-chunks-of-the-same-size-easily-in-javascript
function chunk_arr(arr, chunk_size){
    const results = [];
    const clone = [...arr]

    
    while (clone.length) {
        results.push(clone.splice(0, chunk_size));
    }
    
    return results;
}



window.submit = async function() {

    const account = (await web3.eth.getAccounts())[0]
    if(!account && web3.currentProvider.isMetaMask){
        return document.getElementById('activate-wrap').className = 'shown'
    } else if(!account) {
        return document.getElementById('install-wrap').className = 'shown'
    }

    const local_update_locations = Object.keys(local_letters)
        // .filter(loc => local_letters[loc])
        .map(loc => loc.split(','))

    try {

        const total_price = get_total_price()


        const chunks = chunk_arr(local_update_locations, 30)
        const writes = chunks.map(chunk => Write(
            chunk.map(l => l[0]),
            chunk.map(l => l[1]),
            chunk.map(l => '0x' + (256 + local_letters[l].charCodeAt(0)).toString(16).slice(-2) ),
            get_total_price(chunk)
        ))

        await Promise.all(writes)

        // await Write(
        //  local_update_locations.map(l => l[0]),
        //  local_update_locations.map(l => l[1]),
        //  local_update_locations.map(l => '0x' + (256 + local_letters[l].charCodeAt(0)).toString(16).slice(-2) ),
        //  total_price
        // )


        local_update_locations.forEach(loc => pending_letters[loc] = local_letters[loc])
        local_letters = {}
    } catch (e) {
        console.warn(e)
    }

    render()
}


document.getElementById('heatmap').onchange = e => {
    heatmap = !heatmap
    render()
    // console.log(heatmap)
}
document.getElementById('save').onclick = async e => {
    submit()
}

document.getElementById('install-wrap').onclick = e => {
    if(e.target.id === 'install-wrap' || e.target.id === 'x') document.getElementById('install-wrap').className=''
}

document.getElementById('install-button').onclick= e => {
    document.getElementById('install-wrap').className=''
}

document.getElementById('activate-wrap').onclick = e => {
    if(e.target.id === 'activate-wrap' || e.target.id === 'x') document.getElementById('activate-wrap').className=''
}

document.getElementById('activate-button').onclick= e => {
    document.getElementById('activate-wrap').className=''
}

const hide_help = () => {

    ramjet.transform(
        document.getElementById('instructions-body'),
        document.getElementById('instructions-header'),
        {done(){
            document.getElementById('instructions-body').style.display = 'block'
            document.getElementById('instructions-body-wrap').style.display = 'none'
        },easing: ramjet.easeOut}
    )

    document.getElementById('instructions-body').style.display = 'none'
}

const show_help = () => {
    document.getElementById('instructions-body-wrap').style.display = 'flex'
    document.getElementById('instructions-header').style.borderRadius = '0'

    ramjet.transform(
        document.getElementById('instructions-header'),
        document.getElementById('instructions-body'),
        {done(){
            document.getElementById('instructions-body').style.display = 'block'
            document.getElementById('instructions-body-wrap').style.display = 'flex'
        },easing: ramjet.easeOut}
    )

    document.getElementById('instructions-header').style.borderRadius = '3px'
    document.getElementById('instructions-body').style.display = 'none'
}

document.getElementById('instructions').onclick = show_help
document.getElementById('instructions-body-wrap').onclick = e => {
    if(['instructions-body-wrap', 'explore-button'].indexOf(e.target.id) >=0) {
        hide_help()
    }
}

// const instructions = document.getElementById('instructions-body-wrap')
// instructions.onclick = e => {
//  if(['instructions-body-wrap', 'explore-button'].indexOf(e.target.id) >=0)
//  //  instructions.classList.toggle("shown")
// }

window.onresize = render

// render()